import { IStoreEntity } from "../interfaces/IEntity";
import { IUserInteractibleEntity } from "../interfaces/IUserInteractibleEntity";
import { examplesUpdated } from "../slices/exampleEntity.slice";

export interface ExampleStoreEntity extends IStoreEntity {
  title: string;
}

export class ExampleEntity extends IUserInteractibleEntity<ExampleStoreEntity> {
  public entityType!: "examples";

  public entityTitle!: string;

  protected updateAction = examplesUpdated;

  public mapFromSource(obj: ExampleStoreEntity): void {
    this.entityTitle = obj.title;
  }

  public mapToSource(): ExampleStoreEntity {
    return {
      id: this.id,
      title: this.entityTitle,
    };
  }

  public setTitle(title: string) {
    this.entityTitle = title;
    this.update();
  }
}
