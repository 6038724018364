import { gql } from "@apollo/client";
import { CreateRequestInput, UserSelectionsDTO } from "steps/api";

const APPLICATION_CONFIGURATION_DATA_TO_RETRIEVE = `
  projectID
  pageTitle
  logo {
    base
    monogram
    full
    favicon
  },
  projectName
  hostname
  languages
  defaultLang
  style
  config {
    homePageUrl
    reservedAreaUrl
    privacyLink
    steps {
      type
    }
    rentConstraints {
      minMonthsToStay
      checkInDays
    }
  }
`;

export const GET_APPLICATION_CONFIGURATION_OPTIONS = () => ({
  variables: {},
});

export const GET_APPLICATION_CONFIGURATION_DATA_TO_RETRIEVE = gql`
  query getSpaceConfiguratorApplicationInfo {
    getSpaceConfiguratorApplicationInfo {
      ${APPLICATION_CONFIGURATION_DATA_TO_RETRIEVE}
    }
  }
`;

const INITIAL_INFO_DATA_TO_RETRIEVE = `
  complex
  zone
  carouselImages
  warnings {
    complexSoldOut
    zoneSoldOut
    complexNotFound
    zoneNotFound
  }
`;

export const GET_INITIAL_INFO_OPTIONS = (
  project_id: string,
  input: {
    checkInDate: string;
    checkOutDate: string;
    complex: string | null;
    zone: string | null;
  },
) => {
  const { complex, zone, ...dates } = input;
  const options = {
    variables: {
      project_id,
      input: {
        ...dates,
        ...(complex && { complex }),
        ...(zone && { zone }),
      },
    },
  };
  return options;
};

export const GET_INITIAL_INFO_DATA_TO_RETRIEVE = gql`
  query getInitialInfo(
    $project_id: ID!
    $input: InitialInfoInput!
  ) {
    getInitialInfo(input: $input, project_id: $project_id) {
      ${INITIAL_INFO_DATA_TO_RETRIEVE}
    }
  }
`;

export const CREATE_REQUEST_MUTATION = gql`
  mutation createRequest(
    $input: CreateRequestUserSelectionInput!
    $project_id: ID!
  ) {
    createRequest(input: $input, project_id: $project_id) {
      __typename
      ... on CreateRequestDone {
        done
        quoteId
      }
      ... on CreateRequestErrors {
        done
        error {
          code
          details
        }
      }
    }
  }
`;

export const CREATE_REQUEST_OPTIONS = (
  userSelections: UserSelectionsDTO,
  project_id: string,
) => {
  const { complex, ...input } = userSelections;
  const options: CreateRequestInput = {
    variables: {
      input,
      project_id,
    },
    fetchPolicy: "no-cache",
  };
  return options;
};

const VALIDATE_REQUEST_DATA_TO_RETRIEVE = `
  valid
  error {
    code
    details
  }
`;

export const GET_VALIDATE_REQUEST_DATA_TO_RETRIEVE = gql`
  query validateRequest(
    $input: ValidateRequestUserSelectionInput!
    $project_id: ID!
  ) {
    validateRequest(input: $input, project_id: $project_id) {
      ${VALIDATE_REQUEST_DATA_TO_RETRIEVE}
    }
  }
`;

export const VALIDATE_REQUEST_OPTION = (
  userSelections: UserSelectionsDTO,
  project_id: string,
  price: number,
) => {
  const { complex, ...input } = userSelections;
  const options: CreateRequestInput = {
    variables: {
      input: {
        ...input,
        price,
      },
      project_id,
    },
    fetchPolicy: "no-cache",
  };
  return options;
};
