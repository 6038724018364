/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import type { RootState } from "store";
import { IToken, IUser } from "../../../utils/types/auth";
import getDomain from "../../../utils/functions/getDomain";

export interface AuthState {
  token: IToken | null;
  user: IUser | null;
  impersonatedUser?: IUser | null;
  isImpersonating: boolean;
}

const initialState: AuthState = {
  token: null,
  user: null,
  impersonatedUser: null,
  isImpersonating: false,
};

export const IMPERSONATED_CLIENT_COOKIE = "impersonatedClient";
export const JWT_RENT_COOKIE = "jwtRent";
export const REFRESH_TOKEN_RENT_COOKIE = "refreshTokenRent";
export const EXPIRES_IN_RENT_COOKIE = "expiresInRent";

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLoggedUser: (state, action: PayloadAction<IUser>) => {
      const cookieOptions = {
        expires: 1,
        domain: getDomain(),
      };
      Cookies.set("doLogout", "false", cookieOptions);
      state.user = action.payload;
    },
    unsetLoggedUser: () => {
      const cookieOptions = {
        expires: 1,
        domain: getDomain(),
      };
      Cookies.set("doLogout", "true", cookieOptions);

      Cookies.remove(IMPERSONATED_CLIENT_COOKIE, cookieOptions);

      return {
        ...initialState,
      };
    },
    setTokenUser: (state, action: PayloadAction<IToken>) => {
      state.token = action.payload;
    },
    setImpersonatedUser: (state, action: PayloadAction<IUser>) => {
      state.isImpersonating = true;
      state.impersonatedUser = action.payload;
    },
  },
});

export const selectCurrentUser = (
  state: RootState,
): IUser | null | undefined => {
  return state.auth.user;
};

export const selectImpersonatedUser = (
  state: RootState,
): IUser | null | undefined => {
  return state.auth.impersonatedUser;
};

export const selectIsImpersonating = (state: RootState): boolean =>
  state.auth.isImpersonating;

export const selectUserLogInStatus = (state: RootState): boolean | null =>
  state.auth.user && !!Cookies.get(JWT_RENT_COOKIE);

export const {
  setLoggedUser,
  unsetLoggedUser,
  setTokenUser,
  setImpersonatedUser,
} = slice.actions;

export const { reducer } = slice;
