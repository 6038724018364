// TODO: Define if this logic has any sense for space-configurator !
export const getNormalizedUrl = (): string =>
  window.location.hostname.indexOf("www.") >= 0
    ? window.location.hostname
    : `www.${window.location.hostname}`;

export const detectUrl = (host?: string): string => {
  let hostname = getNormalizedUrl();

  const urlParams = new URLSearchParams(window.location.search);
  const hostnameParam = urlParams.get("hostname");

  if (
    hostname.indexOf("localhost") >= 0 ||
    hostname.indexOf(".ddns.net") >= 0 ||
    hostname.indexOf("biz-tecma") >= 0 ||
    hostname.indexOf("-demo.tecmasolutions.com") >= 0
  ) {
    if (hostnameParam && hostnameParam.length > 0 && hostnameParam !== "") {
      localStorage.removeItem("space_configurator_rent_hostname");
      delete localStorage.space_configurator_rent_hostname;
      localStorage.setItem("space_configurator_rent_hostname", hostnameParam);
    }

    const storageHostName = localStorage.getItem(
      "space_configurator_rent_hostname",
    );

    if (
      storageHostName &&
      storageHostName.length > 0 &&
      storageHostName !== ""
    ) {
      return storageHostName;
    }

    hostname = host || (process.env.REACT_APP_HOST as string);
  }

  return hostname;
};

export const getToolUrl = (tool: string, hostname?: string): string => {
  const url = new URL(
    `${window.location.protocol}//${window.location.hostname.replace(
      "spaceconfigurator",
      tool,
    )}/`,
  );

  if (process.env.REACT_APP_NODE_ENV === "development") {
    url.searchParams.set("hostname", detectUrl(hostname));
  }

  return url.toString();
};

export const goToReservedArea = (reservedAreaUrl: string) => {
  const url = new URL(reservedAreaUrl);
  url.searchParams.set("returnUrl", window.location.hostname);
  window.location.assign(url.toString());
};
