import { Store } from "@reduxjs/toolkit";
import type { RootState } from "..";
import { ExampleEntityRepository } from "./repositories/exampleEntity.repo";
import { StepsEntityRepository } from "./repositories/steps.repo";

export interface Repositories {
  ExampleEntityRepository: ExampleEntityRepository | undefined;
  StepsEntityRepository: StepsEntityRepository | undefined;
}

export const EntitiesRepositories: Repositories = {
  ExampleEntityRepository: undefined,
  StepsEntityRepository: undefined,
};

export const instantiateRepositories = (
  store: Store<RootState>,
): Repositories => {
  EntitiesRepositories.ExampleEntityRepository = new ExampleEntityRepository(
    store,
  );
  EntitiesRepositories.StepsEntityRepository = new StepsEntityRepository(store);
  return EntitiesRepositories;
};
