import type { PayloadAction } from "@reduxjs/toolkit";
import { all, put, takeEvery } from "redux-saga/effects";
import { userSelectionsToDTO } from "steps/api";
import type { UserSelectionsParams } from "steps/selectors";
import { moodStepSlice } from "../state/slice";
import { moodStepActions } from "./actions";
import { getMoodsByUserSelection } from "./api";
import { genericStepActions } from "../../actions/generic.actions";

function* initConfigSaga(
  userSelectionsParams: PayloadAction<UserSelectionsParams>,
) {
  yield put(moodStepSlice.stepConfigActions.setIsStepLoading(true));
  const userSelectionsDTO = userSelectionsToDTO(
    userSelectionsParams.payload.userSelections,
    userSelectionsParams.payload.checkInDate,
    userSelectionsParams.payload.checkOutDate,
    userSelectionsParams.payload.complex,
  );
  const moodsConfig: MoodStepConfig = yield getMoodsByUserSelection(
    userSelectionsParams.payload.projectId,
    userSelectionsDTO,
  );

  yield put(
    moodStepSlice.stepConfigActions.putConfig({
      // FIXME: we should not receive unavailable moods anymore
      options: moodsConfig.options.filter(({ available }) => available),
    }),
  );
  const defaultMood = moodsConfig.options.find(
    (mood) => mood.default && mood.available,
  );
  if (defaultMood) {
    yield put(moodStepActions.updateMood(defaultMood.name));
  }
  yield put(genericStepActions.initConfigCompleted());
  yield put(moodStepSlice.stepConfigActions.setIsStepLoading(false));
}

function* updateMood(action: PayloadAction<MoodName>): any {
  yield all([
    put(moodStepSlice.stepConfigActions.selectedMood(action.payload)),
  ]);
}

function* updateUnconfirmedMood(action: PayloadAction<MoodName>): any {
  yield all([
    put(
      moodStepSlice.stepConfigActions.selectedUnconfirmedMood(action.payload),
    ),
  ]);
}

export function* moodStepRootInitSaga() {
  yield takeEvery(moodStepActions.initConfig, initConfigSaga);
  yield takeEvery(moodStepActions.updateMood, updateMood);
  yield takeEvery(moodStepActions.updateUnconfirmedMood, updateUnconfirmedMood);
}
