import { logger } from "@tecma/logs-fe";
import { UserSelectionsDTO } from "steps/api";
import Client from "../../../client/Client";
import { graphqlClient } from "../../../client/GraphqlClient";

type UpdateQuoteGenericError = {
  code: string;
  details: [string];
};

type CreateRequestDone = {
  done: boolean;
  quoteid: string;
};

type CreateRequestError = {
  done: boolean;
  error: UpdateQuoteGenericError;
};

export type CreateRequest = CreateRequestDone & CreateRequestError;

export const createRequest = async (
  input: UserSelectionsDTO,
  project_id: string,
  client = graphqlClient,
) => {
  try {
    const createRequestResponse = await client.query<{
      createRequest: CreateRequest;
    }>({
      query: Client.CREATE_REQUEST_MUTATION,
      ...Client.CREATE_REQUEST_OPTIONS(input, project_id),
    });
    return createRequestResponse.data?.createRequest;
  } catch (err) {
    logger.error(err);
    throw Error();
  }
};

export const validateRequest = async (
  input: UserSelectionsDTO,
  project_id: string,
  price: number,
  client = graphqlClient,
): Promise<boolean> => {
  try {
    const validateRequestResponse = await client.query({
      query: Client.GET_VALIDATE_REQUEST_DATA_TO_RETRIEVE,
      ...Client.VALIDATE_REQUEST_OPTION(input, project_id, price),
    });
    return validateRequestResponse.data?.validateRequest.valid;
  } catch (err) {
    console.log(err);
    throw Error();
  }
};
