import React from "react";
import type { Repositories } from "../../store/domain";

const defaultState: Repositories = {
  ExampleEntityRepository: undefined,
  StepsEntityRepository: undefined,
};

export const StoreRepositoriesContext =
  React.createContext<Repositories>(defaultState);
