import { createAction } from "@reduxjs/toolkit";
import { ResetAndOpenStep, ResetSelection, SaveSelection } from "./application";

const APPLICATION_TYPE = "application";

export const applicationActions = {
  resetSelectionsRequested: createAction<ResetSelection | undefined>(
    `${APPLICATION_TYPE}/resetSelectionsRequest`,
  ),
  resetSelectionsSagaWithOpenAlertRequested: createAction(
    `${APPLICATION_TYPE}/resetSelectionsSagaWithOpenAlertRequest`,
  ),
  saveSelectionsRequested: createAction<SaveSelection | undefined>(
    `${APPLICATION_TYPE}/saveSelectionsRequest`,
  ),
  openNextStepRequested: createAction(
    `${APPLICATION_TYPE}/openNextStepRequest`,
  ),
  openSpecificCompletedStepRequested: createAction<number>(
    `${APPLICATION_TYPE}/openSpecificCompletedStepRequest`,
  ),
  closeAlertRequested: createAction(`${APPLICATION_TYPE}/closeAlertRequested`),
  stepClickRequested: createAction<number>(
    `${APPLICATION_TYPE}/stepClickRequested`,
  ),
  resetAndOpenStepRequested: createAction<ResetAndOpenStep>(
    `${APPLICATION_TYPE}/resetAndOpenStepRequested`,
  ),
  closeAlertAndGoToStepRequested: createAction<number>(
    `${APPLICATION_TYPE}/closeAlertAndGoToStepRequested`,
  ),
  goToReservedAreaRequested: createAction(
    `${APPLICATION_TYPE}/goToReservedAreaRequested`,
  ),
};
