import { createAction } from "@reduxjs/toolkit";

const APPLICATION_INIT_TYPE = "applicationInit";

export const applicationInitActions = {
  initApplicationRequested: createAction(
    `${APPLICATION_INIT_TYPE}/initRequest`,
  ),
  showAlertSoldOutRequested: createAction<{
    isComplexSoldOut: boolean;
    isZoneSoldOut: boolean;
  }>(`${APPLICATION_INIT_TYPE}/showAlertSoldOutRequest`),
};
