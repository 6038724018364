import { Action } from "@redux-saga/types";
import { PayloadAction } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { all, call, put, select, take } from "redux-saga/effects";

import { logger } from "@tecma/logs-fe";
import { UserSelectionInput } from "client/modules/Step";
import { firstDatePopupActions } from "components/AlertPopupFactory/components/FirstDatePopup/actions";
import {
  CreateRequestUserSelectionDTO,
  UserSelectionsDTO,
  userSelectionsToDTO,
} from "steps/api";
import {
  UserSelections,
  UserSelectionsParams,
  stepsStateSelectors,
  getIsUserSelectionCompleteSelector,
} from "steps/selectors";
import { applicationStateActions } from "store/slices/applicationState/slice";
import {
  IMPERSONATED_CLIENT_COOKIE,
  JWT_RENT_COOKIE,
  selectCurrentUser,
  selectImpersonatedUser,
} from "store/slices/auth/slice";
import { getCheckInAndCheckOutDateSelector } from "store/slices/dateSelectorState/slice";
import { goToReservedArea } from "utils/functions/detectUrl";
import { STEP_MINIMUM_LOAD_TIME } from "utils/settings";
import { IUser } from "utils/types/auth";
import type { RootState } from "../..";
import { STEP_COMPONENTS_REGISTRY } from "../../../steps";
import { genericStepActions } from "../../../steps/actions/generic.actions";
import type {
  AllStepModules,
  StepRegistry,
  StepType,
  UserSelection,
} from "../../../steps/types/step";
import { EntitiesRepositories } from "../../domain";
import { selectTotalSteps } from "../../domain/slices/steps.slice";
import { applicationStateSelectors } from "../../slices/applicationState/selectors";
import { dateSelectorActions } from "../availabilitySelector/availabilitySelector.actions";
import { wait } from "../utils/waiter";
import { ResetAndOpenStep, ResetSelection, SaveSelection } from "./application";
import { applicationActions } from "./application.actions";
import { createRequest, validateRequest } from "./application.api";

function* lastStepAdvance(currentStepIndex: number) {
  yield put(applicationStateActions.openRecap());
  // TODO: we have to handle stepper completed state
  yield put(applicationStateActions.setStep(currentStepIndex + 1));
  yield put(applicationStateActions.setFarthestStep(currentStepIndex + 1));
}

function* closeCurrentStep(currentStepIndex: number) {
  const currentStepIndexEntity =
    EntitiesRepositories.StepsEntityRepository?.selectAll()?.find(
      (step) => step.getOrder() === currentStepIndex,
    );
  yield currentStepIndexEntity?.close();
}

function* getDecoratedInfo() {
  const projectId: string = yield select(
    applicationStateSelectors.selectProjectID,
  );
  const complex: string = yield select(applicationStateSelectors.selectComplex);

  const selectedDates: Pick<
    UserSelectionInput,
    "checkInDate" | "checkOutDate"
  > = yield select(getCheckInAndCheckOutDateSelector);

  return {
    projectId,
    checkInDate: selectedDates.checkInDate,
    checkOutDate: selectedDates.checkOutDate,
    complex,
  };
}

function* getDecoratedUserSelections() {
  const userSelections: Partial<UserSelections> = yield select(
    stepsStateSelectors.selectUserSelections,
  );
  const decoratedInfo: typeof getDecoratedInfo = yield getDecoratedInfo();

  return {
    userSelections,
    ...decoratedInfo,
  };
}

function* getDecoratedUserSelectionsFromCurrentStep(currentStepType: string) {
  const userSelections: Partial<UserSelections> = yield select(
    stepsStateSelectors.selectUserSelections,
  );
  const currStepTypeIndex =
    Object.keys(userSelections).indexOf(currentStepType);
  const userSelectionsFromCurrentStep: Partial<UserSelections> = Object.keys(
    userSelections,
  ).reduce((acc, stepType, index) => {
    return {
      ...acc,
      [stepType]:
        index < currStepTypeIndex
          ? userSelections[stepType as keyof typeof userSelections]
          : undefined,
    };
  }, {});

  return userSelectionsFromCurrentStep;
}

function getStepModule(
  order: number,
): typeof STEP_COMPONENTS_REGISTRY[keyof StepRegistry] | undefined {
  const stepEntity =
    EntitiesRepositories.StepsEntityRepository?.selectAll()?.find(
      (step) => step.getOrder() === order,
    );
  if (!stepEntity) {
    return undefined;
  }
  const stepModule = STEP_COMPONENTS_REGISTRY[stepEntity.getType()];
  return stepModule;
}

function* openNextStep(currentStepIndex: number) {
  const state: RootState = yield select();
  const currentStepModule = getStepModule(currentStepIndex);
  const firstDate =
    currentStepModule && currentStepModule.firstDateAvailableSelector
      ? currentStepModule.firstDateAvailableSelector(state)
      : undefined;

  if (firstDate) {
    yield put(
      applicationStateActions.openAlert({
        type: "first-date",
        props: {
          firstDateContent: {
            type: state.spaceTypeStep.spaceType || "",
            initiative: state.applicationState.projectInfo?.projectName || "",
            date: firstDate,
            complex:
              currentStepModule?.type === "COMPLEX_STEP"
                ? String(currentStepModule?.userSelectionSelector(state))
                : "",
          },
        },
      }),
    );
    const action: Action = yield take([
      firstDatePopupActions.cancelled,
      dateSelectorActions.datesUpdateCompleted,
    ]);
    if (action.type === firstDatePopupActions.cancelled.type) {
      return;
    }
    const userSelectionsParams: UserSelectionsParams =
      yield getDecoratedUserSelections();
    if (currentStepModule) {
      if (currentStepModule.updateConfigAction) {
        yield put(currentStepModule.updateConfigAction(userSelectionsParams));
      }
      yield take(genericStepActions.initConfigCompleted);
    }
  }
  yield put(applicationStateActions.goToNextStep(currentStepIndex));
  const nextStepEntity =
    EntitiesRepositories.StepsEntityRepository?.selectAll()?.find(
      (step) => step.getOrder() === currentStepIndex + 1,
    );
  if (!nextStepEntity) {
    return;
  }
  const nextStepModule = STEP_COMPONENTS_REGISTRY[nextStepEntity.getType()];
  const userSelections: UserSelectionsParams =
    yield getDecoratedUserSelections();
  yield put(nextStepModule.initConfigAction(userSelections));
  yield closeCurrentStep(currentStepIndex);
  yield all([
    take(genericStepActions.initConfigCompleted),
    wait(STEP_MINIMUM_LOAD_TIME),
  ]);
  const isAvailable: boolean = yield select(
    nextStepModule.areOptionsAvailableSelector,
  );

  if (!isAvailable) {
    yield put(
      applicationStateActions.openAlert({
        type: "warning",
        props: {
          confirmButtonText: "alert.reset.confirm-no-availability-reset",
          content: "alert.reset.content-no-availability-reset",
        },
      }),
    );
    yield put(applicationActions.resetSelectionsSagaWithOpenAlertRequested());
  }
  yield nextStepEntity.open();
}

export function* openNextStepSaga() {
  const state: RootState = yield select();
  const currentStepIndex =
    applicationStateSelectors.selectCurrentStepIndex(state);
  const totalSteps = selectTotalSteps(state);
  if (currentStepIndex === totalSteps) {
    yield lastStepAdvance(currentStepIndex);
    yield closeCurrentStep(currentStepIndex);
  } else {
    yield openNextStep(currentStepIndex);
  }
}

export function* closeAlert() {
  yield put(applicationStateActions.closeAlert());
}

export function closeStep(stepIndex: number) {
  const stepToClose =
    EntitiesRepositories.StepsEntityRepository?.selectAll()?.find(
      (step) => step.getOrder() === stepIndex,
    );
  if (!stepToClose) {
    throw new Error("Missing step");
  }
  stepToClose.close();
}

function* closeOpenStep() {
  const currentOpenStepIndex: number = yield select(
    applicationStateSelectors.selectCurrentStepIndex,
  );
  const maxStepIndex: number = yield select(selectTotalSteps);
  if (currentOpenStepIndex <= maxStepIndex) {
    yield closeStep(currentOpenStepIndex);
    yield put(applicationStateActions.setStep(currentOpenStepIndex));
    const currentStep =
      EntitiesRepositories.StepsEntityRepository?.selectAll()?.find(
        (step) => step.getOrder() === 1,
      );
    if (currentStep) {
      const currentStepModule = STEP_COMPONENTS_REGISTRY[currentStep.type];
      yield put(currentStepModule.setStepLoading(true));
    }
  }
}

export function* openSpecificStep(
  specificStepNumber: number,
  initStepAgain = false,
) {
  const state: RootState = yield select();
  const totalSteps = selectTotalSteps(state);
  const prevOpenStepIndex =
    applicationStateSelectors.selectCurrentStepIndex(state);
  if (prevOpenStepIndex <= totalSteps) {
    closeStep(prevOpenStepIndex);
  }
  if (specificStepNumber > totalSteps) {
    yield put(applicationStateActions.openRecap());
    yield put(applicationStateActions.setStep(specificStepNumber));
  } else {
    const nextStep =
      EntitiesRepositories.StepsEntityRepository?.selectAll()?.find(
        (step) => step.getOrder() === specificStepNumber,
      );
    if (!nextStep) {
      throw new Error("Missing step");
    }
    const stepModule = STEP_COMPONENTS_REGISTRY[nextStep.getType()];
    if (initStepAgain) {
      const userSelections: UserSelectionsParams =
        yield getDecoratedUserSelections();
      yield put(stepModule.initConfigAction(userSelections));
      yield take(genericStepActions.initConfigCompleted);
    }

    yield put(applicationStateActions.setStep(specificStepNumber));
    nextStep.open();
  }
}

export function* openSpecificCompletedStep(action: PayloadAction<number>) {
  yield openSpecificStep(action.payload, false);
  yield put(applicationStateActions.closeAlert());
}

export function* resetAndOpenStep(action: PayloadAction<ResetAndOpenStep>) {
  const {
    currentStepIndex,
    clickedStepIndex,
    currentStepModule,
    newUserSelection,
  } = action.payload;
  yield put(
    currentStepModule.updateUserSelectionAction(
      newUserSelection as unknown as never, // FIXME
    ),
  );
  const undoSteps =
    EntitiesRepositories.StepsEntityRepository?.selectAll()?.filter(
      (step) => step.getOrder() > currentStepIndex,
    );
  const resetActions = undoSteps?.map((step) => {
    const stepModule = STEP_COMPONENTS_REGISTRY[step.getType()];
    return put(stepModule.resetUserSelectionAction());
  });
  if (resetActions) {
    yield all(resetActions);
  }
  yield put(applicationStateActions.closeAlert());
  yield put(applicationStateActions.setCarouselIndex(0));
  if (clickedStepIndex && clickedStepIndex < currentStepIndex) {
    yield put(
      applicationActions.openSpecificCompletedStepRequested(clickedStepIndex),
    );
    yield put(applicationStateActions.setFarthestStep(currentStepIndex));
  } else {
    yield openNextStepSaga();
  }
}

const getStepModuleFromIndex = (stepIndex: number) => {
  if (!stepIndex) {
    return null;
  }
  const currentStep =
    EntitiesRepositories.StepsEntityRepository?.selectAll()?.find(
      (step) => step.getOrder() === stepIndex,
    );
  const currentStepType = currentStep?.type as StepType;
  return STEP_COMPONENTS_REGISTRY[currentStepType] as AllStepModules;
};

export function* stepClickRequested(action: PayloadAction<number>) {
  const clickedStepIndex = action.payload;

  const currentStepIndex: number = yield select(
    applicationStateSelectors.selectCurrentStepIndex,
  );
  const farthermostStepIndex: number = yield select(
    applicationStateSelectors.selectFarthestStepIndex,
  );

  const isClickedStepOpen = clickedStepIndex === currentStepIndex;
  const isClickedFarthermostStep = clickedStepIndex === farthermostStepIndex;

  const isFarthermostStepOpen = farthermostStepIndex === currentStepIndex;

  const state: RootState = yield select();

  const totalSteps = selectTotalSteps(state);

  const selectedStepIndex =
    currentStepIndex > totalSteps ? clickedStepIndex : currentStepIndex;

  const isCurrentLastStep = currentStepIndex === totalSteps;

  const openStepModule = getStepModuleFromIndex(selectedStepIndex);

  if (!openStepModule) {
    throw Error(`Missing step for index ${selectedStepIndex}`);
  }

  const userSelection: UserSelection = yield select(
    openStepModule.userSelectionSelector,
  );

  const unconfirmedUserSelection: UserSelection = yield select(
    openStepModule.unconfirmedUserSelectionSelector,
  );

  const isUserSelectionModified: boolean = yield select(
    openStepModule.isUserSelectionModified ||
      (() => userSelection !== unconfirmedUserSelection),
  );
  const isUserSelectionComplete: boolean = yield select(
    getIsUserSelectionCompleteSelector(openStepModule, userSelection),
  );

  const userSelectionRequiresConfirm =
    isUserSelectionModified && !isCurrentLastStep;

  if (isUserSelectionComplete) {
    // Click confirm on last active step
    if (isClickedFarthermostStep && isClickedStepOpen) {
      yield put(
        openStepModule.updateUnconfirmedUserSelectionAction(
          userSelection as unknown as never,
        ),
      );
      yield put(applicationActions.openNextStepRequested());
    }
    // Click confirm on previous open step
    else if (isClickedStepOpen) {
      if (userSelectionRequiresConfirm) {
        yield put(
          applicationStateActions.openAlert({
            type: "warning",
            props: {
              confirmButtonText: "alert.reset.confirm",
              cancelButtonText: "alert.reset.abort",
              content: "alert.reset.content-step-back",
              confirmButtonAction: applicationActions.resetAndOpenStepRequested(
                {
                  currentStepIndex,
                  currentStepModule: openStepModule,
                  newUserSelection: unconfirmedUserSelection,
                },
              ),
              cancelButtonAction:
                applicationActions.closeAlertAndGoToStepRequested(
                  farthermostStepIndex,
                ),
            },
          }),
        );
      } else if (isUserSelectionModified) {
        if (currentStepIndex === totalSteps) {
          yield put(
            openStepModule.updateUnconfirmedUserSelectionAction(
              userSelection as unknown as never,
            ), // FIXME:
          );
          yield put(applicationActions.openNextStepRequested());
        } else {
          yield put(
            applicationActions.resetAndOpenStepRequested({
              currentStepIndex,
              currentStepModule: openStepModule,
              newUserSelection: unconfirmedUserSelection,
            }),
          );
        }
      } else {
        yield closeCurrentStep(currentStepIndex);
        yield put(
          applicationActions.openSpecificCompletedStepRequested(
            farthermostStepIndex,
          ),
        );
      }
    }
    // Click on closed step
    else if (!isClickedStepOpen) {
      if (!isFarthermostStepOpen && userSelectionRequiresConfirm) {
        yield put(
          applicationStateActions.openAlert({
            type: "warning",
            props: {
              confirmButtonText: "alert.reset.confirm",
              cancelButtonText: "alert.reset.abort",
              content: "alert.reset.content-step-back",
              confirmButtonAction: applicationActions.resetAndOpenStepRequested(
                {
                  currentStepIndex,
                  currentStepModule: openStepModule,
                  newUserSelection: unconfirmedUserSelection,
                  clickedStepIndex,
                },
              ),
              cancelButtonAction:
                applicationActions.closeAlertAndGoToStepRequested(
                  clickedStepIndex,
                ),
            },
          }),
        );
      } else {
        const newStepToOpenModule = getStepModuleFromIndex(clickedStepIndex);
        if (newStepToOpenModule?.checkDataChangesAction) {
          const userSelectionsParams: UserSelectionsParams =
            yield getDecoratedUserSelections();
          const userSelections: UserSelection =
            yield getDecoratedUserSelectionsFromCurrentStep(
              newStepToOpenModule.type,
            );
          yield put(
            newStepToOpenModule.checkDataChangesAction({
              userSelectionsParams,
              userSelections,
            }),
          );
        }
        yield put(
          openStepModule.updateUnconfirmedUserSelectionAction(
            userSelection as unknown as never,
          ), // FIXME:
        );
        yield put(
          applicationActions.openSpecificCompletedStepRequested(
            clickedStepIndex,
          ),
        );
      }
    }
  }
}

export function* closeAlertAndGoToStep(action: PayloadAction<number>) {
  const stepToOpenIndex = action.payload;
  const currentStepIndex: number = yield select(
    applicationStateSelectors.selectCurrentStepIndex,
  );
  const currentStepModule = getStepModuleFromIndex(currentStepIndex);
  if (currentStepModule) {
    const currentStepSelection: UserSelection = yield select(
      currentStepModule.userSelectionSelector,
    );
    yield put(
      currentStepModule.updateUnconfirmedUserSelectionAction(
        currentStepSelection as unknown as never, // FIXME
      ),
    );
  }
  yield put(applicationStateActions.closeAlert());
  yield openSpecificStep(stepToOpenIndex);
}

export function* resetSelectionsSagaWithOpenAlert() {
  yield put(applicationStateActions.closeRecap());
  yield put(applicationStateActions.setFarthestStep(1));
  yield openSpecificStep(1, true);
}

export function* resetSelectionsSaga(action: PayloadAction<ResetSelection>) {
  const onlyCloseOpenStep = action?.payload?.closeOpenStep;
  yield put(applicationStateActions.closeAlert());
  yield put(applicationStateActions.closeRecap());
  yield put(applicationStateActions.setFarthestStep(1));
  if (onlyCloseOpenStep) {
    yield closeOpenStep();
  } else {
    yield openSpecificStep(1, true);
  }
}

function* sendNewConfiguration(
  configuration: CreateRequestUserSelectionDTO,
  projectId: string,
  noRedirect = false,
) {
  const projectInfo: ProjectInfo = yield select(
    applicationStateSelectors.selectProjectInfo,
  );
  const { reservedAreaUrl } = projectInfo.config;
  yield createRequest(configuration, projectId)
    .then((res) => {
      if (res?.done) {
        if (!noRedirect) {
          goToReservedArea(reservedAreaUrl);
        }
      } else {
        logger.error(res?.error);
        throw Error(res?.error.code);
      }
    })
    .catch((e) => {
      logger.error(e);
      throw Error(e);
    });
}

export function* goToReservedAreaSaga() {
  const projectInfo: ProjectInfo = yield select(
    applicationStateSelectors.selectProjectInfo,
  );
  const { reservedAreaUrl } = projectInfo.config;

  goToReservedArea(reservedAreaUrl);
}

function* validateConfigurationSaga(
  configuration: UserSelectionsDTO,
  projectId: string,
) {
  const totalPrice: number = yield select(stepsStateSelectors.selectTotalPrice);
  const isValid: boolean = yield validateRequest(
    configuration,
    projectId,
    totalPrice,
  );
  if (!isValid) {
    yield put(
      applicationStateActions.openAlert({
        type: "warning",
        props: {
          confirmButtonText: "alert.reset.confirm-no-availability-reset",
          content: "alert.reset.content-no-availability-reset",
        },
      }),
    );
    yield put(applicationActions.resetSelectionsSagaWithOpenAlertRequested());
  }
  return isValid;
}

export function* saveSelectionsSaga(action: PayloadAction<SaveSelection>) {
  const isSigningUp = action.payload?.isSigningUp;
  const skipValidation = action.payload?.skipValidation;
  yield put(applicationStateActions.setIsSavingConfiguration(true));
  const userSelectionsParams: UserSelectionsParams =
    yield getDecoratedUserSelections();

  const { userSelections, checkInDate, checkOutDate, complex, projectId } =
    userSelectionsParams;

  const totalPrice: number = yield select(stepsStateSelectors.selectTotalPrice);

  const configuration = userSelectionsToDTO(
    userSelections,
    checkInDate,
    checkOutDate,
    complex,
  );

  let isValid = true;

  if (!skipValidation && !isSigningUp) {
    isValid = yield call(validateConfigurationSaga, configuration, projectId);
  }

  if (isValid) {
    const user: IUser = yield select(selectCurrentUser);
    if (isSigningUp || (user && Cookies.get(JWT_RENT_COOKIE))) {
      const { client: clientId }: IUser = Cookies.get(
        IMPERSONATED_CLIENT_COOKIE,
      )
        ? yield select(selectImpersonatedUser)
        : user;
      const newConfigurationParams: CreateRequestUserSelectionDTO = {
        ...configuration,
        clientId,
        price: totalPrice,
      };
      yield sendNewConfiguration(
        newConfigurationParams,
        projectId,
        isSigningUp,
      );
    } else {
      yield put(
        applicationStateActions.openAlert({
          type: "login",
          props: {
            isConfigurationComplete: true,
          },
        }),
      );
    }
  }
  yield put(applicationStateActions.setIsSavingConfiguration(false));
}
