import Cookies from "js-cookie";
import { LoginByProjectInput } from "client/modules/Auth";
import { QuoteRentInput } from "client/modules/Quote";
import {
  IMPERSONATED_CLIENT_COOKIE,
  JWT_RENT_COOKIE,
} from "store/slices/auth/slice";
import Client from "../../../client/Client";
import { graphqlClient } from "../../../client/GraphqlClient";

export const createQuote = async (
  projectID: string,
  serialisedState: QuoteRentInput,
  sendClientEmail: boolean,
  emailInfo: unknown,
  client = graphqlClient,
) => {
  try {
    const userInfo = await client.query({
      query: Client.CREATE_QUOTE_MUTATION,
      ...Client.CREATE_QUOTE_OPTIONS(
        projectID,
        serialisedState,
        true,
        emailInfo,
      ),
    });
    return userInfo.data?.createQuotesRent;
  } catch (err) {
    console.log(err);
    throw Error();
  }
};

export const login = async (
  loginByProjectInput: LoginByProjectInput,
  client = graphqlClient,
) => {
  try {
    const userInfo = await client.query({
      query: Client.DO_LOGIN,
      ...Client.DO_LOGIN_OPTIONS(loginByProjectInput),
    });
    return userInfo.data?.loginByProject;
  } catch (err) {
    console.log(err);
    throw Error();
  }
};

export const getUserByJWT = async (
  projectId: string,
  client = graphqlClient,
) => {
  try {
    const userInfo = await client.query({
      query: Client.GET_USER_INFO,
      ...Client.GET_USER_INFO_DEFAULT_OPTIONS(
        projectId,
        !Cookies.get(JWT_RENT_COOKIE),
      ),
    });
    return userInfo.data?.getUserByJWT;
  } catch (err) {
    console.log(err);
    throw Error();
  }
};

export const getClient = async (
  projectId: string,
  clientId = Cookies.get(IMPERSONATED_CLIENT_COOKIE) || "",
  client = graphqlClient,
) => {
  try {
    const clientInfo = await client.query({
      query: Client.GET_CLIENT,
      ...Client.GET_CLIENT_DEFAULT_OPTIONS(projectId, clientId),
    });
    return clientInfo.data?.getClient;
  } catch (err) {
    console.log(err);
    throw Error();
  }
};

export const refreshToken = async (
  newRefreshToken: string,
  email: string,
  client = graphqlClient,
) => {
  try {
    const token = await client.query({
      query: Client.REFRESH_TOKEN,
      ...Client.REFRESH_TOKEN_DEFAULT_OPTIONS({
        refreshToken: newRefreshToken,
        email,
      }),
    });
    return token.data?.refreshToken;
  } catch (err) {
    console.log(err);
    throw Error();
  }
};
