import React, { useEffect } from "react";
import classNames from "classnames";
import { Header } from "components/Header";
import { PageContent } from "components/PageContent";
import { applicationStateSelectors } from "store/slices/applicationState/selectors";
import { RootState } from "store";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "store/sagas/auth/auth.actions";
import { DefaultProps } from "../../utils/types/defaultProps";

// styles
import "./page.scss";

// Required Props
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface PageRequiredProps {}

// Optional Props
type PageOptionalProps = DefaultProps;

// Combined required and optional props to build the full prop interface
export interface PageProps extends PageRequiredProps, PageOptionalProps {}

// use the optional prop interface to define the default props
const defaultProps: PageOptionalProps = {
  "data-testid": "space-configurator-page",
};

const Page: React.FC<PageProps> = ({ className, ...rest }) => {
  const classList = classNames("space-configurator-page", className);
  const dispatch = useDispatch();
  const projectInfo = useSelector(
    applicationStateSelectors.selectProjectInfo,
  ) as ProjectInfo;
  const { user, token } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    dispatch(
      authActions.initAuth({
        projectId: projectInfo.projectID,
        token: token!,
        user: user!,
      }),
    );
  }, []);

  useEffect(() => {
    if (token && user) {
      dispatch(authActions.startCountDown({ token, user }));
    }
  }, [token, user]);

  return (
    <div className={classList} {...rest}>
      <Header projectInfo={projectInfo} />
      <PageContent />
    </div>
  );
};

Page.defaultProps = defaultProps as Partial<PageOptionalProps>;

export default React.memo(Page);
