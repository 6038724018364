import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import type { RootState } from "../..";
import { generateID } from "../../utils/generateID";
import type { ExampleStoreEntity } from "../entities/exampleEntity.entity";

export const getExampleStoreState = (state: RootState) => state.examples;

export const exampleAdapter = createEntityAdapter<ExampleStoreEntity>({
  // Assume IDs are stored in a field other than `book.id`
  selectId: (example) => example.id,
});

const slice = createSlice({
  name: "examples",
  initialState: exampleAdapter.getInitialState(),
  reducers: {
    examplesLoaded: (state, action) => {
      const { examples } = action.payload;
      exampleAdapter.setAll(state, examples);
    },
    examplesAdded: (state, action) => {
      const example = cloneDeep(action.payload);
      if (!example.id) {
        example.id = generateID();
      }
      return exampleAdapter.addOne(state, example);
    },
    examplesDeleted: (state, action) => {
      const { id } = action.payload;
      exampleAdapter.removeOne(state, id);
    },
    examplesUpdated: (state, action) =>
      exampleAdapter.updateOne(state, {
        id: action.payload.id,
        changes: action.payload,
      }),
  },
});

export const {
  selectById: selectExampleById,
  selectIds: selectExampleIds,
  selectEntities: selectExampleEntities,
  selectAll: selectAllExamples,
  selectTotal: selectTotalExamples,
} = exampleAdapter.getSelectors((state: RootState) =>
  getExampleStoreState(state),
);

export const {
  examplesLoaded,
  examplesAdded,
  examplesDeleted,
  examplesUpdated,
} = slice.actions;

export const { reducer } = slice;
