import type { UserSelectionsParams } from "steps/selectors";
import { PayloadAction } from "@reduxjs/toolkit";
import { put, takeEvery, delay } from "redux-saga/effects";
import { userSelectionsToDTO } from "steps/api";

import { applicationStateActions } from "store/slices/applicationState/slice";
import { packStepSlice } from "../state/slice";
import { packStepActions } from "./actions";
import { getPacksByUserSelection } from "./api";
import { genericStepActions } from "../../actions/generic.actions";

function* initConfigSaga(
  userSelectionsParams: PayloadAction<UserSelectionsParams>,
): any {
  yield put(packStepSlice.stepConfigActions.setIsStepLoading(true));
  const userSelectionsDTO = userSelectionsToDTO(
    userSelectionsParams.payload.userSelections,
    userSelectionsParams.payload.checkInDate,
    userSelectionsParams.payload.checkOutDate,
    userSelectionsParams.payload.complex,
  );
  const packsConfig: PackStepConfig = yield getPacksByUserSelection(
    userSelectionsParams.payload.projectId,
    userSelectionsDTO,
  );
  yield put(packStepSlice.stepConfigActions.putConfig(packsConfig));
  yield put(
    packStepSlice.stepConfigActions.setSelectedPacks(
      packsConfig.options
        ?.filter((pack) => pack.included)
        .map((pack) => pack.name) || [],
    ),
  );
  yield put(genericStepActions.initConfigCompleted());
  yield put(packStepSlice.stepConfigActions.setIsStepLoading(false));
  if (packsConfig.options?.length) {
    yield delay(1000);
    yield put(applicationStateActions.openAlert({ type: "packs", props: {} }));
  }
}

function* updateSelectedPacks(action: PayloadAction<PackName[]>): any {
  yield put(packStepSlice.stepConfigActions.setSelectedPacks(action.payload));
}

function* updateUnconfirmedPacks(action: PayloadAction<PackName[]>): any {
  yield put(
    packStepSlice.stepConfigActions.setUnconfirmedPacks(action.payload),
  );
}

export function* packStepRootInitSaga() {
  yield takeEvery(packStepActions.initConfig, initConfigSaga);
  yield takeEvery(packStepActions.updateSelectedPacks, updateSelectedPacks);
  yield takeEvery(
    packStepActions.updateUnconfirmedPacks,
    updateUnconfirmedPacks,
  );
}
