import Client from "client/Client";
import { graphqlClient } from "client/GraphqlClient";
import { UserSelectionInput } from "client/modules/Step";

export const getComplexesByUserSelection = async (
  projectId: string,
  input: UserSelectionInput,
  client = graphqlClient,
) => {
  try {
    const userInfo = await client.query({
      query: Client.GET_COMPLEX_BY_USER_SELECTION,
      ...Client.GET_SPACES_BY_USER_SELECTION(projectId, input),
    });
    return userInfo.data?.getComplexesByUserSelection;
  } catch (err) {
    console.log(err);
    throw Error();
  }
};
