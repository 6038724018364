import type { PayloadAction } from "@reduxjs/toolkit";
import type { UserSelectionsParams } from "steps/selectors";
import { put, takeEvery } from "redux-saga/effects";
import { userSelectionsToDTO } from "steps/api";
import { floorStepSlice } from "../state/slice";
import { floorStepActions } from "./actions";
import { getFloorsByUserSelection } from "./api";
import { genericStepActions } from "../../actions/generic.actions";

function* selectDefaultFloor(floors: Array<Floor>) {
  const defaultFloor = floors.find((floor) => floor.default);
  if (defaultFloor) {
    yield put(floorStepActions.updateFloor(defaultFloor.number));
  } else {
    yield put(floorStepActions.updateFloor(floors[0].number));
  }
}

function* initConfigSaga(
  userSelectionsParams: PayloadAction<UserSelectionsParams>,
) {
  yield put(floorStepSlice.stepConfigActions.setIsStepLoading(true));
  const userSelectionsDTO = userSelectionsToDTO(
    userSelectionsParams.payload.userSelections,
    userSelectionsParams.payload.checkInDate,
    userSelectionsParams.payload.checkOutDate,
    userSelectionsParams.payload.complex,
  );
  const config: FloorStepConfig = yield getFloorsByUserSelection(
    userSelectionsParams.payload.projectId,
    userSelectionsDTO,
  );
  yield put(floorStepSlice.stepConfigActions.putConfig(config));
  if (config.options?.length) {
    yield selectDefaultFloor(config.options);
  }
  yield put(genericStepActions.initConfigCompleted());
  yield put(floorStepSlice.stepConfigActions.setIsStepLoading(false));
}

function* updateFloor(action: PayloadAction<FloorNumber>): any {
  yield put(floorStepSlice.stepConfigActions.setFloor(action.payload));
}

function* updateUnconfirmedFloor(action: PayloadAction<FloorNumber>): any {
  yield put(
    floorStepSlice.stepConfigActions.setUnconfirmedFloor(action.payload),
  );
}

export function* floorStepRootInitSaga() {
  yield takeEvery(floorStepActions.initConfig, initConfigSaga);
  yield takeEvery(floorStepActions.updateFloor, updateFloor);
  yield takeEvery(
    floorStepActions.updateUnconfirmedFloor,
    updateUnconfirmedFloor,
  );
}
