import type { PayloadAction } from "@reduxjs/toolkit";
import { all, put, putResolve, takeEvery } from "redux-saga/effects";
import { userSelectionsToDTO } from "steps/api";
import type { UserSelectionsParams } from "steps/selectors";

import { planimetryStepSlice } from "../state/slice";
import { planimetryStepActions } from "./actions";
import { getPlansByUserSelection } from "./api";
import { genericStepActions } from "../../actions/generic.actions";

function* initConfigSaga(
  userSelectionsParams: PayloadAction<UserSelectionsParams>,
) {
  yield put(planimetryStepSlice.stepConfigActions.setIsStepLoading(true));
  const userSelectionsDTO = userSelectionsToDTO(
    userSelectionsParams.payload.userSelections,
    userSelectionsParams.payload.checkInDate,
    userSelectionsParams.payload.checkOutDate,
    userSelectionsParams.payload.complex,
  );
  const planimetryStepConfig: PlanimetryStepConfig =
    yield getPlansByUserSelection(
      userSelectionsParams.payload.projectId,
      userSelectionsDTO,
    );
  yield putResolve(
    planimetryStepSlice.stepConfigActions.putConfig(planimetryStepConfig),
  );
  const defaultPlanimetry = planimetryStepConfig.options.find(
    (planimetry: Planimetry) => planimetry.default && planimetry.available,
  );
  if (defaultPlanimetry) {
    yield put(planimetryStepActions.updatePlanimetry(defaultPlanimetry));
  }
  yield put(genericStepActions.initConfigCompleted());
  yield put(planimetryStepSlice.stepConfigActions.setIsStepLoading(false));
}

function* updatePlanimetry(action: PayloadAction<Planimetry>) {
  yield all([
    put(
      planimetryStepSlice.stepConfigActions.selectedPlanimetry(action.payload),
    ),
  ]);
}

function* updateUnconfirmedPlanimetry(action: PayloadAction<Planimetry>) {
  yield all([
    put(
      planimetryStepSlice.stepConfigActions.setUnconfirmedPlanimetry(
        action.payload,
      ),
    ),
  ]);
}

export function* planimetryStepRootInitSaga() {
  yield takeEvery(planimetryStepActions.initConfig, initConfigSaga);
  yield takeEvery(planimetryStepActions.updatePlanimetry, updatePlanimetry);
  yield takeEvery(
    planimetryStepActions.updateUnconfirmedPlanimetry,
    updateUnconfirmedPlanimetry,
  );
}
