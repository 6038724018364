import { takeEvery, takeLatest } from "redux-saga/effects";
import { getStepModulesResetSagas } from "steps/saga";
import { applicationInitActions } from "./init/init.actions";
import { applicationActions } from "./application.actions";
import { applicationInitSaga, showAlertSoldOut } from "./init/init.saga";
import {
  resetSelectionsSaga,
  resetSelectionsSagaWithOpenAlert,
  saveSelectionsSaga,
  goToReservedAreaSaga,
  openNextStepSaga,
  openSpecificCompletedStep,
  closeAlert,
  stepClickRequested,
  resetAndOpenStep,
  closeAlertAndGoToStep,
} from "./application.saga";

export function* applicationMux() {
  yield takeEvery(
    applicationInitActions.initApplicationRequested,
    applicationInitSaga,
  );
  yield takeEvery(
    applicationActions.resetSelectionsRequested,
    resetSelectionsSaga,
  );
  yield takeEvery(
    applicationActions.resetSelectionsSagaWithOpenAlertRequested,
    resetSelectionsSagaWithOpenAlert,
  );
  yield takeEvery(
    applicationActions.saveSelectionsRequested,
    saveSelectionsSaga,
  );
  yield takeEvery(
    applicationActions.goToReservedAreaRequested,
    goToReservedAreaSaga,
  );
  const stepModulesStepSagas = getStepModulesResetSagas();
  for (let i = 0; i < stepModulesStepSagas.length; i += 1) {
    const stepModulesStepSaga = stepModulesStepSagas[i];
    yield takeEvery(
      applicationActions.resetSelectionsRequested,
      stepModulesStepSaga,
    );
    yield takeEvery(
      applicationActions.resetSelectionsSagaWithOpenAlertRequested,
      stepModulesStepSaga,
    );
  }
  yield takeEvery(applicationActions.openNextStepRequested, openNextStepSaga);
  yield takeEvery(
    applicationActions.openSpecificCompletedStepRequested,
    openSpecificCompletedStep,
  );
  yield takeEvery(applicationActions.closeAlertRequested, closeAlert);
  yield takeEvery(applicationActions.stepClickRequested, stepClickRequested);
  yield takeEvery(
    applicationActions.resetAndOpenStepRequested,
    resetAndOpenStep,
  );
  yield takeEvery(
    applicationActions.closeAlertAndGoToStepRequested,
    closeAlertAndGoToStep,
  );
  yield takeLatest(
    applicationInitActions.showAlertSoldOutRequested,
    showAlertSoldOut,
  );
}
