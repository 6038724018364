import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "@tecma/ui";
import { Helmet } from "react-helmet";
import { setRealDeviceHeight } from "utils/functions/device";
import { Scaffold } from "components/Scaffold";
import { AlertPopupFactory } from "components/AlertPopupFactory";
import { GenericErrorPage } from "components/GenericErrorPage";
import { applicationInitActions } from "./store/sagas/application/init/init.actions";
import { applicationStateSelectors } from "./store/slices/applicationState/selectors";
import { deviceStateActions } from "./store/slices/deviceState/slice";
import { Routes } from "./routes";

// styles
import "./theme/global.scss";

const App: React.FC = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(applicationStateSelectors.selectIsLoading);
  const scaffoldClassList = isLoading ? "app-is-loading" : "";
  const projectInfo = useSelector(
    applicationStateSelectors.selectProjectInfo,
  ) as ProjectInfo;
  const hasError = useSelector(applicationStateSelectors.selectHasError);

  useEffect(() => {
    dispatch(applicationInitActions.initApplicationRequested());
  }, []);

  useEffect(() => {
    setRealDeviceHeight();
    function handleResize() {
      dispatch(
        deviceStateActions.setDeviceDimensions({
          height: window.innerHeight,
          width: window.innerWidth,
        }),
      );
      setRealDeviceHeight();
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <BrowserRouter>
      <Helmet>
        <link
          rel='icon'
          href={projectInfo?.logo.favicon}
          sizes='16x16'
          type='image/png'
        />
        <title>{projectInfo?.pageTitle}</title>
      </Helmet>

      {hasError ? (
        <GenericErrorPage />
      ) : (
        <>
          <Scaffold className={scaffoldClassList}>
            {isLoading ? (
              <Spinner type='dotted' className='initial-loader' />
            ) : (
              <Routes />
            )}
          </Scaffold>
          {!isLoading && <AlertPopupFactory />}
        </>
      )}
    </BrowserRouter>
  );
};

export default App;
