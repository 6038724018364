import { IRepository } from "../interfaces/IRepository";
import {
  ExampleEntity,
  ExampleStoreEntity,
} from "../entities/exampleEntity.entity";
import {
  exampleAdapter,
  examplesAdded,
  examplesDeleted,
  examplesUpdated,
  getExampleStoreState,
} from "../slices/exampleEntity.slice";

export class ExampleEntityRepository extends IRepository<
  ExampleEntity,
  ExampleStoreEntity
> {
  protected Factory = ExampleEntity;

  protected stateRetrieveFunction = getExampleStoreState;

  protected storeAdapter = exampleAdapter;

  protected createAction = examplesAdded;

  protected updateAction = examplesUpdated;

  protected deleteAction = examplesDeleted;

  public mapFromSource(obj: ExampleStoreEntity): ExampleEntity {
    return new ExampleEntity(obj, this.store);
  }
}
