import type { UserSelectionsParams } from "steps/selectors";
import { createAction } from "@reduxjs/toolkit";

const PACK_STEP_SELECTOR_TYPE = "packStep";

export const packStepActions = {
  initConfig: createAction<UserSelectionsParams>(
    `${PACK_STEP_SELECTOR_TYPE}/initConfig`,
  ),
  updateSelectedPacks: createAction<PackName[]>(
    `${PACK_STEP_SELECTOR_TYPE}/updateSelectedPacks`,
  ),
  updateUnconfirmedPacks: createAction<PackName[]>(
    `${PACK_STEP_SELECTOR_TYPE}/updateUnconfirmedPacks`,
  ),
  setIsStepLoading: createAction<boolean>(
    `${PACK_STEP_SELECTOR_TYPE}/setIsStepLoadingRequest`,
  ),
};
