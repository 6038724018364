import React from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { Button, Icon } from "@tecma/ui";
import { useTranslation } from "@tecma/i18n";
import { DefaultProps } from "utils/types/defaultProps";
import { goToReservedArea } from "utils/functions/detectUrl";
import { applicationStateActions } from "store/slices/applicationState/slice";
import { IUser } from "utils/types/auth";
import { selectImpersonatedUser } from "store/slices/auth/slice";
import { HeaderLoggedUser } from "../HeaderLoggedUser";

// styles
import "./headerMenu.scss";
import { HeaderLanguageSelector } from "../HeaderLanguageSelector";

// Required Props
interface HeaderMenuRequiredProps {
  projectInfo: ProjectInfo;
  headerRef: React.MutableRefObject<HTMLDivElement>;
  isLogged: boolean;
}

// Optional Props
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface HeaderMenuOptionalProps extends DefaultProps {
  user?: IUser | null;
  setMobileMenuIsOpen?: (isOpen: boolean) => void;
  isMobile?: boolean;
}

// Combined required and optional props to build the full prop interface
export interface HeaderMenuProps
  extends HeaderMenuRequiredProps,
    HeaderMenuOptionalProps {}

// use the optional prop interface to define the default props
const defaultProps: HeaderMenuOptionalProps = {
  "data-testid": "spaceConfigurator-headerMenu",
};

const HeaderMenu: React.FC<HeaderMenuProps> = ({
  className,
  projectInfo,
  headerRef,
  user,
  isLogged,
  setMobileMenuIsOpen,
  isMobile,
  ...rest
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleOpenLogin = () => {
    if (setMobileMenuIsOpen) {
      setMobileMenuIsOpen(false);
    }
    dispatch(
      applicationStateActions.openAlert({
        type: "login",
        props: {
          confirmButtonText: "",
        },
      }),
    );
  };

  const handleGoToReservedArea = () => {
    goToReservedArea(projectInfo.config.reservedAreaUrl);
  };

  const getUserRole = () => {
    if (user?.role) {
      return t([`header.user-role.${user.role}`, user.role]);
    }
    return t("header.hello");
  };

  const impersonatedUser = useSelector(selectImpersonatedUser);

  const classList = classNames("spaceConfigurator-headerMenu", className);

  return (
    <div className={classList} {...rest}>
      <div className='element-container'>
        <HeaderLanguageSelector
          headerRef={headerRef}
          availableLanguages={projectInfo.languages}
        />
      </div>
      <div className='element-container'>
        <a
          href={projectInfo.config.homePageUrl}
          target='_blank'
          rel='noreferrer'
        >
          <Icon iconName='globe' size='small' />
          {t("header.back-to-website")}
        </a>
      </div>
      {!isMobile && isLogged && impersonatedUser && (
        <div className='element-container'>
          <div className='logged-user'>
            <Icon iconName='user-circle' />
            <p>
              <span>{getUserRole()}: </span>
              <br />
              {user?.firstName} {user?.lastName}
            </p>
          </div>
        </div>
      )}
      <div className='element-container'>
        {!isLogged ? (
          <Button
            onClick={handleOpenLogin}
            transparent
            iconName='user'
            iconSize='small'
            className='login'
          >
            {t("header.login")}
          </Button>
        ) : (
          <HeaderLoggedUser
            headerRef={headerRef}
            handleGoToReservedArea={handleGoToReservedArea}
            user={user}
            impersonatedUser={impersonatedUser}
          />
        )}
      </div>
    </div>
  );
};

HeaderMenu.defaultProps = defaultProps as Partial<HeaderMenuOptionalProps>;

export default React.memo(HeaderMenu);
