import type { RootState } from "../..";

const selectLoadingState = (state: RootState) =>
  state.applicationState.isLoading;

const selectIsSavingConfiguration = (state: RootState) =>
  state.applicationState.isSavingConfiguration;

const selectRecapState = (state: RootState) =>
  state.applicationState.isOpenRecap;

const selectPriceDetailsState = (state: RootState) =>
  state.applicationState.isOpenPriceDetails;

const selectAlertState = (state: RootState) =>
  state.applicationState.isOpenAlert;

const selectProjectInfoState = (state: RootState) =>
  state.applicationState.projectInfo;

const selectCurrentStepIndexState = (state: RootState) =>
  state.applicationState.currentStepIndex;

const selectFarthestStepIndexState = (state: RootState) =>
  state.applicationState.farthestStepIndex;

const selectCurrentCarouselIndexState = (state: RootState) =>
  state.applicationState.currentCarouselIndex;

const selectCurrentAlertState = (state: RootState) =>
  state.applicationState.alert;

const selectProjectID = (state: RootState) =>
  state.applicationState.projectInfo?.projectID;

const selectRentConstraints = (state: RootState) =>
  state.applicationState.projectInfo?.config.rentConstraints;

const selectComplex = (state: RootState) =>
  state.applicationState.projectInfo?.config?.complex;

const selectZone = (state: RootState) =>
  state.applicationState.projectInfo?.config?.zone;

const selectZoomedStepIndex = (state: RootState) =>
  state.applicationState.zoomedStepIndex;

const selectHasError = (state: RootState) => state.applicationState.hasError;

const selectDefaultCarouselImages = (state: RootState) =>
  state.applicationState.projectInfo?.config.carouselImages;

const selectToken = (state: RootState) => state.applicationState.token;

export const applicationStateSelectors = {
  selectIsLoading: selectLoadingState,
  selectIsOpenRecap: selectRecapState,
  selectIsOpenPriceDetails: selectPriceDetailsState,
  selectIsOpenAlert: selectAlertState,
  selectProjectInfo: selectProjectInfoState,
  selectCurrentStepIndex: selectCurrentStepIndexState,
  selectFarthestStepIndex: selectFarthestStepIndexState,
  selectCurrentCarouselIndex: selectCurrentCarouselIndexState,
  selectCurrentAlert: selectCurrentAlertState,
  selectProjectID,
  selectRentConstraints,
  selectComplex,
  selectZone,
  selectZoomedStepIndex,
  selectHasError,
  selectDefaultCarouselImages,
  selectIsSavingConfiguration,
  selectToken,
};
