import React from "react";
import { Trans, useTranslation } from "@tecma/i18n";
import { Button, Spinner } from "@tecma/ui";
import classNames from "classnames";
import { useSelector } from "react-redux";

import { useDevice } from "hooks/device/device";
import type { DefaultProps } from "utils/types/defaultProps";
import { applicationStateSelectors } from "store/slices/applicationState/selectors";
import ConfirmButtonMobile from "components/RecapSection/components/ConfirmButtonMobile";
import { PriceDetails } from "components/RecapSection/components/PriceDetails";
import type { PriceDetailsTransformed } from "steps/types/step";
import { getPriceDetailsViewMode } from "utils/functions/prices";
import "./recap-footer.scss";

interface RecapFooterRequiredProps {
  onReset: () => void;
  onConfirm: () => void;
  isCompleted: boolean;
  totalPrice: number;
  priceDetails: PriceDetailsTransformed[];
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface RecapFooterOptionalProps extends DefaultProps {}

export interface RecapFooterProps
  extends RecapFooterRequiredProps,
    RecapFooterOptionalProps {}

const defaultProps: RecapFooterOptionalProps = {
  "data-testid": "space-configurator-recapFooter",
};

const RecapFooter: React.FC<RecapFooterProps> = ({
  onReset,
  onConfirm,
  totalPrice,
  isCompleted,
  priceDetails,
  className,
}) => {
  const { t, i18n } = useTranslation();
  const isOpen = useSelector(applicationStateSelectors.selectIsOpenRecap);
  const farthestStepIndex = useSelector(
    applicationStateSelectors.selectFarthestStepIndex,
  );
  const isSavingConfiguration = useSelector(
    applicationStateSelectors.selectIsSavingConfiguration,
  );
  const { type, typeDetailed } = useDevice();

  const recapFooterClasses = classNames("recap-footer", {
    "is-mobile": type === "mobile",
    "is-open": isOpen,
    className,
  });
  const saveButtonClasses = classNames("recap-footer-buttons-save", {
    "is-mobile": type === "mobile",
  });
  const resetButtonClasses = classNames("recap-footer-buttons-reset", {
    "is-mobile": type === "mobile",
    "is-open": isOpen,
  });

  const priceFormatted = new Intl.NumberFormat(i18n.language).format(
    totalPrice,
  );

  return (
    <div className={recapFooterClasses}>
      {type === "desktop" && typeDetailed !== "desktop_s" && (
        <>
          <p className='recap-footer-desc'>
            <Trans i18nKey='recap.footer-text' components={{ b: <span /> }} />
          </p>
          <p className='recap-footer-price'>
            <Trans
              i18nKey='recap.price-per-month'
              components={{ s: <span />, br: <br /> }}
              values={{
                price: priceFormatted,
                currency: "€",
                extraContent:
                  getPriceDetailsViewMode(priceDetails) === "sentence"
                    ? "*"
                    : "",
              }}
            />
          </p>
        </>
      )}

      <div className='recap-footer-buttons'>
        {["desktop_m", "desktop_l"].includes(typeDetailed) &&
          !!priceDetails.length && <PriceDetails prices={priceDetails} />}
        {type === "mobile" && isOpen === false ? (
          <ConfirmButtonMobile />
        ) : (
          <Button
            className={saveButtonClasses}
            secondary
            disabled={!isCompleted && !isSavingConfiguration}
            onClick={!isSavingConfiguration ? onConfirm : () => {}}
            fluid
          >
            {t("recap.save-proceed")}
            {isSavingConfiguration && (
              <Spinner type='dotted-circle' size='small' />
            )}
          </Button>
        )}
        <Button
          transparent
          className={resetButtonClasses}
          disabled={farthestStepIndex === 1}
          onClick={() => onReset()}
          size='small'
          fluid
        >
          {t("recap.reset-choices")}
        </Button>
      </div>
    </div>
  );
};

RecapFooter.defaultProps = defaultProps as Partial<RecapFooterOptionalProps>;

export default RecapFooter;
