import React from "react";
import classNames from "classnames";
import { useTranslation } from "@tecma/i18n";
import { DefaultProps } from "utils/types/defaultProps";
import { useDispatch, useSelector } from "react-redux";

import { useDevice } from "hooks/device/device";
import { RecapHeader } from "components/RecapSection/components/RecapHeader";
import { RecapContent } from "components/RecapSection/components/RecapContent";
import { RecapFooter } from "components/RecapSection/components/RecapFooter";
import { applicationStateSelectors } from "store/slices/applicationState/selectors";
import { applicationStateActions } from "store/slices/applicationState/slice";
import { stepsStateSelectors } from "steps/selectors";
import { selectTotalSteps } from "store/domain/slices/steps.slice";
import { applicationActions } from "store/sagas/application/application.actions";
import { priceDetailsToArray } from "utils/functions/prices";

// styles
import "./recapSection.scss";

// Required Props
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface RecapSectionRequiredProps {}

// Optional Props
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface RecapSectionOptionalProps extends DefaultProps {}

// Combined required and optional props to build the full prop interface
export interface RecapSectionProps
  extends RecapSectionRequiredProps,
    RecapSectionOptionalProps {}

// use the optional prop interface to define the default props
const defaultProps: RecapSectionOptionalProps = {
  "data-testid": "space-configurator-recapSection",
};

const RecapSection: React.FC<RecapSectionProps> = ({ className, ...rest }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { type } = useDevice();
  const isOpen = useSelector(applicationStateSelectors.selectIsOpenRecap);

  const totalPrice = Math.round(
    useSelector(stepsStateSelectors.selectTotalPrice),
  );
  const priceDetails = useSelector(stepsStateSelectors.selectPriceDetails);
  const totalSteps = useSelector(selectTotalSteps);
  const currentStepIndex = useSelector(
    applicationStateSelectors.selectCurrentStepIndex,
  );

  const isCompleted = currentStepIndex > totalSteps;

  const classList = classNames(
    "space-configurator-recapSection",
    { isOpen, "is-mobile": type === "mobile" },
    className,
  );

  const handleToggle = () => {
    if (isOpen) {
      dispatch(applicationStateActions.closeRecap());
      dispatch(applicationStateActions.closePriceDetails());
    } else {
      dispatch(applicationStateActions.openRecap());
    }
  };

  const handleResetChoices = () => {
    dispatch(
      applicationStateActions.openAlert({
        type: "warning",
        props: {
          confirmButtonText: "alert.reset.confirm",
          cancelButtonText: "alert.reset.abort",
          content: "alert.reset.content-reset",
          confirmButtonAction: applicationActions.resetSelectionsRequested(),
          cancelButtonAction: applicationActions.closeAlertRequested(),
        },
      }),
    );
  };

  const handleConfirmChoices = () => {
    dispatch(applicationActions.saveSelectionsRequested());
  };

  const priceDetailsArray = priceDetailsToArray(priceDetails);

  return (
    <div className={classList} {...rest}>
      <RecapHeader
        onToggle={handleToggle}
        isOpen={isOpen}
        totalPrice={totalPrice}
        priceDetails={priceDetailsArray}
      />
      <RecapContent isOpen={type === "tablet" ? true : isOpen} />
      <RecapFooter
        onReset={handleResetChoices}
        onConfirm={handleConfirmChoices}
        isCompleted={isCompleted}
        totalPrice={totalPrice}
        priceDetails={priceDetailsArray}
        className={isOpen ? "is-open" : ""}
      />
    </div>
  );
};

RecapSection.defaultProps = defaultProps as Partial<RecapSectionOptionalProps>;

export default React.memo(RecapSection);
