import { all, put, call, takeLatest, delay } from "redux-saga/effects";
import {
  IMPERSONATED_CLIENT_COOKIE,
  JWT_RENT_COOKIE,
  setImpersonatedUser,
  setLoggedUser,
  setTokenUser,
  unsetLoggedUser,
} from "store/slices/auth/slice";
import { PayloadAction } from "@reduxjs/toolkit";
import { logger } from "@tecma/logs-fe";
import Cookies from "js-cookie";
import { DateTime } from "luxon";
import { LoginByProjectInput } from "client/modules/Auth";
import getDomain from "utils/functions/getDomain";
import {
  IToken,
  IUser,
  LoginByProjectData,
  LoginByProjectDataAndId,
} from "utils/types/auth";
import { authActions } from "./auth.actions";
import { getClient, getUserByJWT, login, refreshToken } from "./auth.api";
import { applicationActions } from "../application/application.actions";

const cookieOptions = {
  expires: 1, // durata di 1 giorno, se non viene messa la durata diventa un session cookie che viene eliminato dopo aver chiuso il browser
  domain: getDomain(),
};

function* getUserInfo(action: PayloadAction<string>): any {
  const userInfo = yield getUserByJWT(action.payload);
  yield put(setLoggedUser(userInfo));
  return userInfo;
}

function* loggedInStuff(action: PayloadAction<LoginByProjectDataAndId>): any {
  const { token, user, isConfigurationComplete, skipValidation } =
    action.payload;

  Cookies.set(JWT_RENT_COOKIE, token.accessToken, cookieOptions);
  Cookies.set("refreshTokenRent", token.refreshToken, cookieOptions);
  Cookies.set("expiresInRent", token.expiresIn, cookieOptions);
  Cookies.set("clientIdRent", user.client, cookieOptions);

  yield all([put(setLoggedUser(user)), put(setTokenUser(token))]);

  if (isConfigurationComplete) {
    yield put(applicationActions.saveSelectionsRequested({ skipValidation }));
  }
}

function* doLogin(action: PayloadAction<LoginByProjectInput>): any {
  const { user, token, project_id: projectId } = yield login(action.payload);

  //   yield loggedInStuff({
  //     payload: { user, token, projectId },
  //     type: "auth/loggedInStuff",
  //   });
  //   applicationStateActions.closeAlert();
}

function* doLogout(): any {
  const domain = yield getDomain();

  Cookies.remove(JWT_RENT_COOKIE, { domain });
  Cookies.remove("refreshTokenRent", { domain });
  Cookies.remove("expiresInRent", { domain });
  Cookies.remove("clientIdRent", { domain });
  localStorage.removeItem("user_selection");

  yield put(unsetLoggedUser());
}

function* setNewCookies() {
  if (
    Cookies.get(JWT_RENT_COOKIE) &&
    Cookies.get("refreshTokenRent") &&
    Cookies.get("expiresInRent")
  ) {
    const token: IToken = {
      accessToken: Cookies.get(JWT_RENT_COOKIE) || "",
      refreshToken: Cookies.get("refreshTokenRent") || "",
      expiresIn: Cookies.get("expiresInRent") || "",
    };
    yield put(setTokenUser(token));
  }
}

function* getNewToken(
  timeout: number,
  baseTimeout: number,
  token: IToken,
  user: IUser,
): any {
  const logout = Cookies.get("doLogout");
  const { refreshToken: newRefreshToken, expiresIn } = token;

  try {
    if (
      logout === "false" &&
      user &&
      baseTimeout >= 0 &&
      newRefreshToken &&
      expiresIn === Cookies.get("expiresInRent")
    ) {
      const response = yield refreshToken(newRefreshToken, user.email);
      try {
        if (response) {
          const refreshedToken: IToken = response;
          Cookies.set(
            JWT_RENT_COOKIE,
            refreshedToken.accessToken,
            cookieOptions,
          );
          Cookies.set(
            "refreshTokenRent",
            refreshedToken.refreshToken,
            cookieOptions,
          );
          Cookies.set("expiresInRent", refreshedToken.expiresIn, cookieOptions);
          yield put(setTokenUser(refreshedToken));
        }
      } catch (error) {
        yield doLogout();
      }
    } else if (
      logout === "false" &&
      baseTimeout > 0 &&
      expiresIn !== Cookies.get("expiresInRent")
    ) {
      yield setNewCookies();
    } else if (baseTimeout < 0 || logout === "true") {
      yield doLogout();
    }
  } catch (e) {
    logger.error(e);
    yield delay(timeout);
    yield getNewToken(timeout, baseTimeout, token, user);
  }
}

function* startCountDown(action: PayloadAction<LoginByProjectData>) {
  const { token, user } = action.payload;
  const targetTime =
    DateTime.fromISO(token.expiresIn).toMillis() -
    Number(process.env.REACT_APP_TIMEOUT_TOKEN);
  const baseTimeout = targetTime - Date.now();
  const maxTimeoutValue = 2147483647;
  const timeout = baseTimeout > maxTimeoutValue ? maxTimeoutValue : baseTimeout;
  if (timeout > 0) {
    yield delay(timeout);
    if (Cookies.get("doLogout") === "false") {
      yield getNewToken(timeout, baseTimeout, token, user);
    }
  } else if (token && token.accessToken && token.expiresIn) {
    if (DateTime.fromISO(token.expiresIn) < DateTime.now()) {
      yield doLogout();
    }
  }
}

function* initAuth(action: PayloadAction<LoginByProjectDataAndId>): any {
  const { user, token, projectId } = action.payload;
  const logout = Cookies.get("doLogout");
  if (logout === "true" && user) {
    yield put(unsetLoggedUser());
  }

  // set auth info for logged in user
  if (
    Cookies.get(JWT_RENT_COOKIE) &&
    Cookies.get("refreshTokenRent") &&
    Cookies.get("expiresInRent") &&
    (!token || !user)
  ) {
    const newToken: IToken = {
      accessToken: Cookies.get(JWT_RENT_COOKIE) || "",
      refreshToken: Cookies.get("refreshTokenRent") || "",
      expiresIn: Cookies.get("expiresInRent") || "",
    };

    yield put(setTokenUser(newToken));
    try {
      yield call(getUserInfo, {
        payload: projectId,
        type: "auth/getUserByJWT",
      });
      if (Cookies.get(IMPERSONATED_CLIENT_COOKIE)) {
        const clientInfo = yield getClient(projectId);
        yield put(
          setImpersonatedUser({ ...clientInfo, client: clientInfo.id }),
        );
      }
    } catch (error) {
      yield doLogout();
    }
  }
}

export default function* authSaga() {
  yield takeLatest(authActions.initAuth, initAuth);
  yield takeLatest(authActions.getUserByJWT, getUserInfo);
  yield takeLatest(authActions.doLogin, doLogin);
  yield takeLatest(authActions.doLogout, doLogout);
  yield takeLatest(authActions.loggedInStuff, loggedInStuff);
  yield takeLatest(authActions.startCountDown, startCountDown);
}
